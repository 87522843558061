import { Text } from '@chakra-ui/react'
import { MiimosaCestQuoi, FinancementParticipatif } from '@components/common'

export const faqQuestions = [
  {
    title: 'Comment fonctionnent les opérations ?',
    bgColor: 'brand_light_brown',
    textColor: 'brand_dark_brown',
    text: (
      <>
        <Text>
          Nestlé s’engage à travers ses marques FRISKIES®, RICORÉ® et NESTLÉ® CÉRÉALES pour soutenir des projets
          d’agriculture régénératrice.
          <br />
          <br />
          Chaque marque accompagne les producteurs avec un accompagnement financier afin d’accélérer la transition.
          <br />
          <br />
          Les agriculteurs font une collecte de financement participatif sur MiiMOSA pour faire découvrir leurs enjeux
          et permettre aux citoyens de contribuer à des projets vertueux et durables pour leur exploitation.
        </Text>
      </>
    ),
  },
  {
    title: 'Génération Régénération, c’est quoi ?',
    bgColor: 'brand_brown',
    textColor: 'white',
    text: (
      <>
        <Text color="white">
          En 2023, nous soutenons les projets de 4 agriculteurs situés autour des usines de céréales de petit-déjeuner
          de Rumilly (Haute-Savoie) et d’Itancourt (Aisne). Ils sont producteurs de blé en agriculture biologique et
          souhaitent poursuivre leurs efforts pour des pratiques agricoles durables.
          <br />
          Guy et Sébastien, comme Méryl et Benoît, conduisent leurs fermes en agriculture biologique et adoptent des
          principes agroécologiques pour travailler avec la nature et améliorer la qualité de leurs sols.
        </Text>
      </>
    ),
  },
  {
    ...MiimosaCestQuoi,
    bgColor: 'brand_yellow',
    textColor: 'brand_dark_brown',
  },
  {
    ...FinancementParticipatif,
    bgColor: 'brand_dark_brown',
    textColor: 'white',
  },
  {
    title: 'Nestlé en quelques mots',
    bgColor: 'brand_light_brown',
    textColor: 'brand_dark_brown',
    text: (
      <>
        <Text>
          Depuis plus de 150 ans, Nestlé met son savoir-faire au service de l’alimentation et s’est fixé le dessein
          ambitieux d’améliorer la qualité de vie et contribuer à un avenir plus sain. Guidés par ces valeurs, les 10
          000 collaborateurs de Nestlé en France cultivent l’esprit pionnier d’Henri Nestlé, et contribuent tous les
          jours à l’amélioration des produits d’une centaine de marques connues et reconnues. La France est le 1er
          marché européen de Nestlé, le 3e dans le monde et 70% des matières premières utilisées dans ses usines sont
          françaises.
        </Text>
      </>
    ),
  },
]
