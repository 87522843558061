import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Cross',
  viewBox: '0 0 21 21',
  path: (
    <>
      <path d="M20.0837 0.684326L1.28125 19.3156" stroke="#605140" />
      <path d="M1.28125 0.684326L20.0837 19.3156" stroke="#605140" />
    </>
  ),
})
