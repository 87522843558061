import { Flex, Text, Button, Image } from '@chakra-ui/react'
import { s3ImageURL } from '@miimosa/common'
import { useMountedBreakpoints } from '@miimosa/design-system'
import { Right, Left, NestleLogo } from './icons'
import Slider from 'react-slick'
import React from 'react'

function Arrow({
  icon,
  left = 'none',
  right = 'none',
  onClick,
}: {
  icon: React.ReactNode
  left: string
  right: string
  onClick?: () => void
}) {
  return (
    <Flex
      position="absolute"
      left={left}
      right={right}
      onClick={onClick}
      bottom="46%"
      zIndex={10}
      borderRadius="full"
      backgroundColor="rgba(255,255,255,0.3)"
      h="40px"
      w="40px"
      justifyContent="center"
      alignItems="center"
      _hover={{ cursor: 'pointer' }}
    >
      {icon}
    </Flex>
  )
}

const Slide = ({
  image,
  bgPosition,
  logo,
  color,
  text,
  link,
}: {
  image: string
  bgPosition: string
  logo: React.ReactNode
  color: string
  text: React.ReactNode
  link: string
}) => {
  const isMobile = useMountedBreakpoints({ base: true, md: false }, false)

  return (
    <Flex
      position="relative"
      h={{ base: '520px', md: '450px' }}
      bgImage={s3ImageURL(image)}
      bgSize="cover"
      bgPosition={bgPosition}
      w="full"
    >
      <Flex
        w="full"
        zIndex={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        rowGap={8}
        h={{ base: 'full', md: 'none' }}
      >
        <Flex
          minH={{ base: '300px', md: 'auto' }}
          h={{ base: 'full', md: '45%' }}
          zIndex={2}
          direction={{ base: 'column', md: 'row' }}
          w="full"
        >
          <Flex
            mt={{ base: 16, md: 'unset' }}
            h={{ base: '65%', md: 'unset' }}
            bgColor={color + 'CC'}
            w={{ base: 'full', md: '70%' }}
            pl={{ base: 4, md: 4, lg: 48 }}
            pr={{ base: 4, md: 4 }}
            justifyContent="center"
            alignItems={{ base: 'start', md: 'center' }}
            rowGap={8}
            py={{ base: 8, md: 0 }}
            direction={{ base: 'column', md: 'row' }}
          >
            {isMobile && (
              <Flex alignItems="center" w={'full'} h={'30%'} justifyContent={'center'}>
                {logo}
              </Flex>
            )}

            <Text
              fontWeight="bold"
              color="white"
              textAlign={{ base: 'left', md: 'center' }}
              fontSize={{ base: 16, md: 20 }}
            >
              {text}
            </Text>
          </Flex>
          {isMobile && (
            <Flex alignItems="center" justifyContent="center" my={4}>
              <OperationButton link={link} color={color} />
            </Flex>
          )}
          {!isMobile && (
            <Flex alignItems="center" pl={10}>
              {logo}
            </Flex>
          )}
        </Flex>
      </Flex>
      {!isMobile && (
        <Flex pos="absolute" bottom="13%" left="50%">
          <OperationButton link={link} color={color} />
        </Flex>
      )}
    </Flex>
  )
}

export const OperationButton = ({ link, color }: { link: string; color: string }) => {
  return (
    <Button
      variant="brandPrimary"
      as={link ? 'a' : 'span'}
      {...(link ? { href: link } : {})}
      bgColor={link ? color : 'gray'}
      borderRadius="full"
      px={4}
      py={2}
      fontWeight="bold"
      fontSize={{ base: 20, md: 24 }}
      left={{ base: '0', md: '-50%' }}
      zIndex={3}
      opacity={link ? 1 : 0.7}
    >
      {link ? 'Découvrir la démarche' : 'À venir'}
    </Button>
  )
}

export const Hero = () => {
  const isMobile = useMountedBreakpoints({ base: true, md: false }, false)
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow right="5%" icon={<Right />} left="none" />,
    prevArrow: <Arrow left="5%" icon={<Left />} right="none" />,
    arrows: !isMobile,
    appendDots: (dots: any) => (
      <Flex m={0} p={0} justifyContent="center" position="absolute" bottom={{ base: '20px', md: '30px' }}>
        {dots}
      </Flex>
    ),
    initialSlide: 2,
  }
  return (
    <Flex w="full" direction="column">
      <Slider {...settings}>
        <Slide
          image="home-nestle-hero.jpg"
          bgPosition="0 30%"
          logo={<NestleLogo color={{ base: 'white', md: '#297a37' }} w={{ base: '150px', md: '250px' }} h={'full'} />}
          color="#297a37"
          text={
            <>
              L’ambition de NESTLÉ® Céréales France : un approvisionnement en blé bio 100% français d’ici 2025. Pour
              cela, les équipes de NESTLÉ® Céréales travaillent avec la coopérative Oxyane pour développer une filière
              bio locale avec des agriculteurs en région Auvergne Rhône Alpes.
            </>
          }
          link="/nestle#demarche"
        />
        <Slide
          image="home-purina-hero.jpg"
          bgPosition="0 20%"
          logo={
            <Image src={s3ImageURL('purina-logo-friskies.png')} width={{ base: '130px', md: '200px' }} height="auto" />
          }
          color="#e01149"
          text={
            <>
              À partir de 22 novembre, FRISKIES® s’engage et participe au financement de 3 projets d’agriculture
              régénératrice avec une enveloppe totale de 30&nbsp;000€. Chaque projet agricole est assuré de recevoir
              5&nbsp;000€. Les 15 000€ restants sont répartis au prorata des votes en ligne entre les trois projets. À
              vous de voter&nbsp;!
            </>
          }
          link="/friskies#demarche"
        />
        <Slide
          image="home-ricore-hero.jpeg"
          bgPosition="0 60%"
          logo={
            <Flex mb={-4}>
              <Image src={s3ImageURL('ricore-logo.png')} width={{ base: '150px', md: '250px' }} height="auto" />
            </Flex>
          }
          color="#b4520a"
          text={
            <>
              Du 14 octobre au 15 novembre, RICORÉ® soutient 3 producteurs laitiers de Challerange avec 20&nbsp;000 €
              pour financer leurs projets écologiques. Votre vote détermine comment répartir ces fonds entre eux:
              choisissez votre projet préféré et aidez nos agriculteurs à adopter des pratiques plus durables&nbsp;!
            </>
          }
          link="/ricore#demarche"
        />
      </Slider>
    </Flex>
  )
}
