import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Right',
  viewBox: '0 0 11 21',
  path: (
    <>
      <path
        d="M10.3697 10.3366L1.37164 20.3345C1.18691 20.5397 0.870768 20.5564 0.665513 20.3716C0.460258 20.1869 0.443619 19.8708 0.628348 19.6655L9.31101 10.0181L0.646444 1.35355C0.451182 1.15828 0.451182 0.841703 0.646444 0.64644C0.841707 0.451179 1.15829 0.451179 1.35355 0.64644L10.3514 9.64426C10.5035 9.79427 10.5402 10.0204 10.4553 10.207C10.4342 10.2533 10.4057 10.2972 10.3697 10.3366Z"
        fill="#63513D"
      />
    </>
  ),
})
