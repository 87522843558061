import { Flex, Button } from '@chakra-ui/react'
import { Link, Icon } from '@miimosa/design-system'
import { LogoFooter } from './icons'

export const Footer = () => {
  return (
    <Flex
      direction="column"
      w="full"
      h="auto"
      px={{ base: 8, md: 16 }}
      py={10}
      alignItems={'center'}
      borderTop="1px solid"
      borderColor="brand_dark_brown"
    >
      <Flex w="full" direction={{ base: 'column', md: 'row' }} rowGap={10}>
        <Flex w="full" direction="column" rowGap={4} justifyContent="center" alignItems="center" color="dark_green">
          <Link href="https://www.miimosa.com" isExternal>
            <Icon name={'Logo2'} size="xl" h="auto" />
          </Link>
          <Flex direction="row" columnGap={2}>
            <Link isExternal href="https://www.instagram.com/MiiMOSA_fr/">
              <Icon name="Instagram" size="4xs" h="auto" />
            </Link>
            <Link isExternal href="https://www.facebook.com/MiiMOSAfr">
              <Icon name="Facebook" size="4xs" />
            </Link>
            <Link isExternal href="https://www.linkedin.com/company/miimosa/">
              <Icon name="LinkedIn" size="4xs" h="auto" />
            </Link>
          </Flex>
          <Link href="https://blog.miimosa.com/" isExternal>
            <Button variant="brandPrimary" p={4} backgroundColor="dark_green">
              Blog MiiMOSA
            </Button>
          </Link>
        </Flex>
        <Flex w="full" direction="column" rowGap={6} justifyContent="center" alignItems="center">
          <Flex direction="column" justifyContent="center" alignItems="center">
            <LogoFooter color="brand_dark_brown" width={{ base: '50px', md: '75px' }} height="auto" mb={4} />

            <Flex direction="row" columnGap={2} color="brand_dark_brown">
              <Link href="https://x.com/NestleFr" isExternal>
                <Icon name="X" size="4xs" h="auto" />
              </Link>
              <Link isExternal href="https://www.instagram.com/nestleenfrance/">
                <Icon name="Instagram" size="4xs" h="auto" />
              </Link>
              <Link isExternal href="https://fr-fr.facebook.com/Nestle.fr/">
                <Icon name="Facebook" size="4xs" m={-1} />
              </Link>
              <Link isExternal href="https://www.linkedin.com/company/nestle-s-a-/">
                <Icon name="LinkedIn" size="4xs" h="auto" />
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
