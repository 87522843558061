import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Left',
  viewBox: '0 0 11 21',
  path: (
    <>
      <path
        d="M0.63028 10.6634L9.62836 0.665522C9.81309 0.460267 10.1292 0.443628 10.3345 0.628357C10.5397 0.813087 10.5564 1.12923 10.3717 1.33449L1.68899 10.9819L10.3536 19.6465C10.5488 19.8417 10.5488 20.1583 10.3536 20.3536C10.1583 20.5488 9.84171 20.5488 9.64645 20.3536L0.648635 11.3557C0.496519 11.2057 0.459843 10.9796 0.544697 10.793C0.565772 10.7467 0.594299 10.7028 0.63028 10.6634Z"
        fill="#63513D"
      />
    </>
  ),
})
