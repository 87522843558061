import { Flex, Heading } from '@chakra-ui/react'
import { faqQuestions } from './data/faqQuestions'
import { AccordionFAQ } from '@components/common'

export const Faq = () => {
  return (
    <Flex position="relative" alignItems="center" direction="column" p={{ base: '30px 0', md: '30px' }} w="full">
      <Heading textAlign="center" size="lg" color="brand_orange" mb={8}>
        FAQ
      </Heading>
      <AccordionFAQ border="none" borderRadius="0" questions={faqQuestions} />
    </Flex>
  )
}
