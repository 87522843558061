import { Image } from '@chakra-ui/react'
import { s3ImageURL } from '@miimosa/common'
import { projects as RicoreProjects } from '@components/ricore'
import { projects as PurinaProjects } from '@components/purina'
import { NestleLogo } from '@components/home/icons'
import { projects as NestleProjects } from '@components/nestle/data/projects'
import React from 'react'

export const operations = [
  {
    slug: 'ricore',
    message:
      'Du 14 octobre au 15 novembre, votez pour aider à répartir 20 000 € entre 3 producteurs laitiers Ricoré et soutenir leurs projets écologiques !',
    state: 'En cours',
    color: 'ricore_yellow',
    logo: <Image src={s3ImageURL('ricore-logo.png')} w={{ base: '50%', md: '30%' }} />,
    projects: RicoreProjects,
  },
  {
    slug: 'friskies',
    message:
      'FRISKIES® s’engage pour l’agriculture régénératrice et accompagne financièrement 3 agriculteurs dans leur transition. Soutenez le projet de votre choix !',
    state: 'En cours',
    color: 'purina_orange',
    logo: <Image src={s3ImageURL('purina-logo-friskies.png')} w={{ base: '50%', md: '20%' }} />,
    projects: PurinaProjects,
  },
  {
    slug: 'nestle',
    message: 'NESTLÉ® CÉRÉALES s’engage et offre 20 000€ à votre projet d’agriculture régénératrice préféré.',
    state: 'Terminé',
    color: 'nestle_green',
    logo: <NestleLogo color="nestle_green" width="auto" boxSize={64} />,
    projects: NestleProjects,
  },
]
