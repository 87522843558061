import { Button, Heading, Flex, Image, Text, Box } from '@chakra-ui/react'
import { Icon, useMountedBreakpoints } from '@miimosa/design-system'
import React from 'react'
import { s3ImageURL } from '@miimosa/common'

export const LaDemarche = () => {
  const isMobile = useMountedBreakpoints({ base: true, lg: false }, false)
  return (
    <Flex position="relative" bgColor="white" w={{ base: 'full', md: '80%' }} mb={12} justifyContent="center">
      <Flex direction="column" alignItems="center" justifyContent="center">
        <Box as="span" id="demarche" visibility="hidden" height="0" transform="translateY(-80px)" />
        <Box as="span" id="engagements" visibility="hidden" height="0" transform="translateY(-80px)" />

        <Flex dir="row" p={4} w={'full'} justifyContent={'center'}>
          <Heading textAlign="center" size="lg" mb={12} fontSize={28}>
            La&nbsp;démarche
          </Heading>
          <Icon
            name="GenerationRegeneration"
            h="40px"
            w="auto"
            position="relative"
            bottom="8px"
            ml={2}
            color="brand_dark_brown"
          />
        </Flex>

        <Flex direction={{ base: 'column', md: 'row' }} columnGap={8} alignItems="center" w="full">
          <Image
            src={s3ImageURL('home-child.png')}
            w={{ base: '90%', md: '40%' }}
            h="auto"
            border="solid 1px"
            borderColor="brand_dark_brown"
            m={0}
            mb={{ base: -20, md: 0 }}
            zIndex={2}
          />
          <Flex
            direction="column"
            w={{ base: '100%', md: '60%' }}
            textColor="white"
            backgroundColor="brand_dark_brown"
            p={10}
            pt={{ base: 32, md: 8 }}
            h="full"
            justifyContent="center"
          >
            <Text mb={4}>
              Nous pensons que chacun d’entre nous a un rôle à jouer dans la préservation de notre avenir.
            </Text>
            <Text fontWeight={700}>
              Nous avons deux programmes dédiés pour soutenir l’agriculture régénératrice en France.
            </Text>
          </Flex>
        </Flex>

        <Flex direction={{ base: 'column', md: 'row' }} columnGap={8} mt={8} w={'full'} alignItems="center">
          <Flex
            direction="column"
            backgroundColor="#F8F4F3"
            p={8}
            border="solid 1px"
            borderColor="brand_dark_brown"
            w={{ base: '90%', md: '50%' }}
            alignItems={'center'}
            mb={{ base: 8, md: 0 }}
            h="full"
            justifyContent="space-between"
          >
            <Heading size="lg" mb={6}>
              Sols Vivants
            </Heading>
            <Flex dir="row" columnGap={4}>
              {!isMobile && (
                <Flex w={'30%'}>
                  <Image src={s3ImageURL('purina-sols-vivants.png')} objectFit="contain" />
                </Flex>
              )}
              <Flex w={{ base: 'full', md: '70%' }}>
                <Text mb={4}>
                  Nestlé s’est engagé en 2018 dans le programme Sols Vivants, piloté par la Fondation Earthworm. Il vise
                  à développer l’adoption de pratiques d’agriculture régénératrice, en fournissant un support technique
                  et financier aux agriculteurs dans leur transition.{' '}
                </Text>
              </Flex>
            </Flex>
            <Flex w="full" dir="row" justifyContent={{ base: 'space-between', md: 'center' }} alignItems={'center'}>
              <Button
                variant="brandPrimary"
                mt={{ base: 'none', md: 'auto' }}
                as="a"
                href="https://www.solsvivants.org"
              >
                En savoir plus
              </Button>
              {isMobile && (
                <Flex w={'40%'}>
                  <Image src={s3ImageURL('purina-sols-vivants.png')} objectFit="contain" />
                </Flex>
              )}
            </Flex>
          </Flex>

          <Flex
            direction="column"
            backgroundColor="#F9F1E2"
            p={8}
            border="solid 1px"
            borderColor="brand_dark_brown"
            w={{ base: '90%', md: '50%' }}
            alignItems={'center'}
            h="full"
            justifyContent="space-between"
          >
            <Heading size="lg" mb={6}>
              Ferme Laitière Bas Carbone
            </Heading>
            <Text mb={4}>
              En partenariat avec l’Institut de l’Elevage (Idele), Nestlé déploie la démarche de la filière laitière «
              Ferme Laitière Bas Carbone » pour accompagner les éleveurs laitiers dans la réduction de leurs émissions
              de gaz à effet de serre (GES) à partir d’un diagnostic individualisé et un plan d’action.
              <br />
              <br />
              En parallèle, les éleveurs ont accès à un accompagnement technique et financier pour mettre en place des
              pratiques plus durables.
            </Text>
            <Button variant="brandPrimary" mt="auto" as="a" href="https://www.ferme-laitiere-bas-carbone.fr/">
              En savoir plus
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
